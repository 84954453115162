import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { VolumeMute, Dialpad, Speaker, CallEnd, Phone} from '@mui/icons-material';
import 'flowbite';
import 'flowbite/dist/flowbite.css';
import './cbot.css';


class Callbot extends React.Component {
    constructor(props) {
        super(props);
        this.audioRef = React.createRef();
        this.recognition = null;
        this.state = {
            name: '',
            email: '',
            phoneNumber: '',
            policyNumber: '',
            isCalling: false,
            showCallingImage: false,
            callDuration: 0,
            showKeypad: false,
            keypadInput: '',
            showWaiting: false,
            formSubmitted: false,
            audioPath: '',
            isMuted: false,
            isSpeakerOn: false,
            volume: 1,
            userResponseReceived: false,
            isListening: false,
            transcription: '',
            isFetchingData: false,
            showSatisfactionPopup: false,
            rating: 0,
            isBotSpeaking: false,
            isImageClicked: false,
            isResponsiveMode: false,
            isCardVisible: false,
            ukuranKecil: false,
        };
        this.coba = document.createElement('AUDIO');
        
        this.handleChange = this.handleChange.bind(this);
        this.startCall = this.startCall.bind(this);
        this.endCall = this.endCall.bind(this);
        this.toggleKeypad = this.toggleKeypad.bind(this);
        this.addKeypadInput = this.addKeypadInput.bind(this);
        this.handleCallbotClick = this.handleCallbotClick.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.startCallHandler = this.startCallHandler.bind(this);
        this.toggleMute = this.toggleMute.bind(this);
        this.toggleSpeaker = this.toggleSpeaker.bind(this);
        this.handleVolumeChange = this.handleVolumeChange.bind(this);
        this.toggleListening = this.toggleListening.bind(this);
        this.handleUserResponse = this.handleUserResponse.bind(this);
        this.handleRatingClick = this.handleRatingClick.bind(this);
        this.handleSatisfactionSubmit = this.handleSatisfactionSubmit.bind(this);
        this.toggleCardVisibility = this.toggleCardVisibility.bind(this);
    }

    componentDidMount() {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (SpeechRecognition) {
            this.recognition = new SpeechRecognition();
            this.recognition.continuous = true;
            this.recognition.lang = 'id-ID'; 
            this.recognition.onstart = () => {
                console.log("Listening...");
                this.setState({ isListening: true, transcription: "Listening..." });
            };
            this.recognition.onend = () => {
                console.log("Stopped listening");
                this.setState({ isListening: false });
            };
            this.recognition.onresult = (event) => {
                const transcript = event.results[0][0].transcript;
                console.log("Transcript:", transcript);
                this.setState({ transcription: transcript });
                if (!this.state.userResponseReceived) {
                    this.handleUserResponse(transcript);
                }
            };
        } else {
            console.error("Speech recognition not supported in this browser.");
        }
    }
    
    componentWillUnmount() {
        if (this.recognition) {
            this.recognition.stop();
        }
    }
    
    
    playAutoResponse() {
        const { name } = this.state;
        fetch('http://localhost:5000/auto_response', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name: name }),
        })
        .then(response => response.blob())
        .then(audioBlob => {
            const audioUrl = URL.createObjectURL(audioBlob);
            const audioElement = new Audio(audioUrl);
            audioElement.play();
            audioElement.onended = () => {
                this.toggleListening(true);  
            };
        })
        .catch(error => {
            console.error("Error fetching auto response:", error);
        });
    }

    componentWillUnmount() {
        if (this.recognition) {
            this.recognition.stop();
        }
    }

    handleTranscription(transcription) {
        console.log("Handling transcription:", transcription);
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
        console.log(`Perubahan state: ${name} => ${value}`);
    }
    
    toggleCardVisibility() {
        this.setState((prevState) => ({
            isCardVisible: !prevState.isCardVisible
        }));
    }
    
    formatCallDuration(duration) {
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const seconds = duration % 60;
    
        return [
            hours.toString().padStart(2, '0'),
            minutes.toString().padStart(2, '0'),
            seconds.toString().padStart(2, '0')
        ].join(':');
    }

    startCall() {
        this.setState({ isCalling: true });
        this.toggleListening(true); 
        this.setState({ showWaiting: true });
        setTimeout(() => {
            this.setState({ showWaiting: false });
            this.callInterval = setInterval(() => {
                this.setState(prevState => ({
                    callDuration: prevState.callDuration + 1
                }));
            }, 200);
            this.handleSubmitForm();
        }, 200);
    }

    endCall() {
        const endCallAudio = new Audio('/root/ocha/endcall.mp3');
        console.log('Attempting to play audio from: /root/ocha/endcall.mp3');
        endCallAudio.play().then(() => {
            console.log('Audio playback started successfully');
            clearInterval(this.callInterval);
            this.coba.pause();
            console.log("Bot audio playback stopped");
            this.toggleListening(false);
            console.log("Stopped listening");
            this.setState({ showSatisfactionPopup: false });
            this.setState({
                isCalling: false,
                callDuration: 0,
                isBotSpeaking: false 
            });
            fetch('https://ocha.cxplore.live/flask/end_call', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to end call');
                } else {
                    console.log('Call ended successfully');
                }
            })
            .catch(error => {
                console.error('Error ending call:', error);
            });
        }).catch(error => {
            console.error('Error playing end call audio:', error);
            console.log('Continuing with end call operations despite audio error');
            clearInterval(this.callInterval);
            this.coba.pause();
            console.log("Bot audio playback stopped");
            this.toggleListening(false);
            console.log("Stopped listening");
            this.setState({ showSatisfactionPopup: false });
            this.setState({
                isCalling: false,
                callDuration: 0,
                isBotSpeaking: false 
            });
            fetch('https://ocha.cxplore.live/flask/end_call', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to end call');
                } else {
                    console.log('Call ended successfully');
                }
            })
            .catch(error => {
                console.error('Error ending call:', error);
            });
        });
    }
    
    

    toggleKeypad() {
        this.setState(prevState => ({
            showKeypad: !prevState.showKeypad,
            ukuranKecil: !prevState.ukuranKecil
        }));
    }

    addKeypadInput(label) {
        this.setState(prevState => ({
            keypadInput: prevState.keypadInput + label
        }));
    }

    handleCallbotClick() {
        this.setState({ formSubmitted: true, isImageClicked: true });
    }

    handleSubmitForm() {
        setTimeout(() => {
            this.setState({ formSubmitted: true });
        }, 200);
    }
  
    addKeypadInput(key) {
        console.log(key);
        const { keypadInput } = this.state;
    
        if (key === '*') {
            this.handleUserResponse('menu');
            this.setState({ keypadInput: '' });
        } else {
            const keypadResponses = ['BP', 'ST', 'CB', 'AS', 'AO', 'PC', 'EC', 'LL', 'CS'];
    
            if (keypadInput.startsWith('BP')) {
                if (key === '5') {
                    this.handleUserResponse('CS');
                    this.setState({ keypadInput: '' });
                } else {
                    const numberPressed = keypadInput.slice(2) + key;
                    const response = keypadResponses[parseInt(numberPressed) - 1];
                    this.handleUserResponse(response);
                }
            } else if (keypadInput.endsWith('1')) {
                const response = 'BP' + key;
                this.handleUserResponse(response);
            } else {
                this.handleUserResponse(keypadResponses[parseInt(key) - 1]);
            }
            this.setState(prevState => ({
                keypadInput: prevState.keypadInput + key
            }));
        }
    }
    
    startCallHandler() {
        try {
            const { name } = this.state;
            if (!name.trim()) {
                console.error('Nama tidak boleh kosong.');
                return;
            }
    
            console.log('Memulai panggilan dengan nama:', name);
            console.log('Starting call...');
            this.setState({ isFetchingData: true, showCallingImage: true });
            this.coba.setAttribute('src', 'https://ocha.cxplore.live/flask/calling_audio');
            this.coba.play();
    
            setTimeout(() => {
                this.setState({ showCallingImage: false, isCalling: true });
    
                this.coba.onended = () => {
                    console.log('Call started, fetching data...');
                    this.setState({ isFetchingData: false });
                    fetch('https://ocha.cxplore.live/flask/start_call', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ name: name })
                    })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Failed to start call');
                            }
                            return response.blob();
                        })
                        .then(responseData => {
                            console.log('Call data received, generating audio...');
                            const audioUrl = URL.createObjectURL(responseData);
                            const audioElement = new Audio(audioUrl);
                            audioElement.play();
    
                            this.setState({ isBotSpeaking: true });
    
                            audioElement.onended = () => {
                                console.log('Audio playback ended');
                                this.setState({ isBotSpeaking: false });
                                this.toggleListening(true);
                            };
    
                            this.callInterval = setInterval(() => {
                                this.setState(prevState => ({
                                    callDuration: prevState.callDuration + 1
                                }));
                            }, 1000);
                        })
                        .catch(error => {
                            console.error('Error starting call:', error);
                            this.setState({ isFetchingData: false });
                        });
                };
            }, 6000);
        } catch (error) {
            console.error('Error starting call:', error);
            this.setState({ isFetchingData: false });
        }
    }
    
    
    

    stopBevaAnimation = () => {
        const bevaElement = document.querySelector('.animated-beva');
        const callingText = document.getElementById('calling-text');

        if (bevaElement) {
            bevaElement.classList.remove('animated-beva');
        }
        if (callingText) {
            callingText.style.display = 'none';
        }
    };

    toggleListening(start) {
        if (this.recognition) {
            if (start) {
                this.recognition.start();
            } else {
                this.recognition.stop();
            }
        }
    }

    toggleMute() {
        this.setState(
            (prevState) => ({
                isMuted: !prevState.isMuted,
                volume: prevState.isMuted ? 1 : 0,
            }),
            () => {
                console.log("isMuted:", this.state.isMuted);
                console.log("volume:", this.state.volume);
                if (this.coba) {
                    console.log("Referensi audio:", this.coba);
                    this.coba.volume = this.state.isMuted ? 0 : 1; 
                } else {
                    console.error("Referensi audio tidak didefinisikan atau null.");
                }
            }
        );
    
        if (!this.state.isMuted && this.coba && this.coba.paused) {
            this.coba.play();
        }
    }
    
    
    toggleSpeaker() {
        const previousVolume = this.state.volume;
        const newVolume = this.state.isSpeakerOn ? 1 : previousVolume;
    
        this.setState(
            prevState => ({
                isSpeakerOn: !prevState.isSpeakerOn,
                volume: newVolume
            }),
            () => {
                console.log("isSpeakerOn:", this.state.isSpeakerOn);
                console.log("volume:", this.state.volume);
                if (this.coba) {
                    console.log("Referensi audio:", this.coba);
                    this.coba.volume = this.state.volume;
                } else {
                    console.error("Referensi audio tidak didefinisikan atau null.");
                }
            }
        );
    }
    
    

    handleVolumeChange(event) {
        const volume = event.target.value;
        this.setState({ volume }, () => {
            if (this.audioRef.current) {
                this.audioRef.current.volume = volume;
            }
        });
    }

    async handleUserResponse(response) {
        try {
            console.log('Sending user response:', response);
            this.toggleListening(false);
    
            const responseData = await fetch('https://ocha.cxplore.live/flask/handle_response', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ response })
            });
    
            if (responseData.ok) {
                console.log('User response received:', response);
    
                const audioData = await responseData.blob();
                const audioUrl = URL.createObjectURL(audioData);
    
                this.coba.setAttribute('src', audioUrl);
                this.coba.play();
    
                this.setState({ isBotSpeaking: true });
    
                this.coba.onended = () => {
                    console.log('Audio end, start listening...');
                    this.toggleListening(true);
                    this.setState({ isBotSpeaking: false });
                };
            } else {
                throw new Error('Failed to handle user response');
            }
        } catch (error) {
            console.error('Error handling user response:', error);
        }
    }
    
    
    handleRatingClick(rating) {
        this.setState({ rating });
    }

    handleSatisfactionSubmit() {
        this.setState({ showSatisfactionPopup: false });
        alert(`Terima kasih atas penilaiannya! Anda memberi nilai: ${this.state.rating} bintang.`);
    }

    render() {
        const { isCardVisible, isCalling, callDuration, showKeypad, showCallingImage, showSatisfactionPopup, rating, ukuranKecil} = this.state;
        const gambarStyle = ukuranKecil ? { width: '50px', height: '50px', marginBottom: '200px' } : { width: '100px', height: '100px' };
        const keypadButtonStyle = ukuranKecil ? 'bg-gray-200 p-1 rounded text-xs focus:outline-none' : 'bg-gray-200 p-4 rounded text-lg focus:outline-none';
        const keypadContainerStyle = showKeypad ? { marginTop: '10px' } : {};
        return (
<div className="relative flex items-center justify-end h-screen p-8">
                <div id="chat-bubble"
                    className="w-16 h-16 bg-gray-800 rounded-full flex items-center justify-center cursor-pointer text-3xl fixed bottom-4 right-4"
                    onClick={this.toggleCardVisibility}
                >
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="white" class="bi bi-telephone" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg>
                </div>
                <div className="flex flex-col items-center p-8 justify-center w-full h-screen md:text-center md:w-3xl">
                    <h1 className="text-2xl font-bold leading-relaxed">Welcome to Callbot, Let's Click Icon</h1>
                    <p className="font-light leading-relaxed">Let's config your persona!</p>
                </div>
                {isCardVisible && (
                    <div className="absolute bottom-20 right-4 max-w-md w-full bg-white rounded-md shadow-md flex flex-col transition-all text-sm" 
                        style={{ maxWidth: '22rem', height: '32rem', bottom: '1rem', right: '1rem', background: showCallingImage ? '#1F2937' : isCalling ? 'white' : 'linear-gradient(#1F2937 10%, #fff 10%)' }}>
                        
                        {!showCallingImage && !isCalling && (
                            <div className="flex justify-between items-center p-4 bg-gray-800 text-white rounded-t-md">
                                <h3 className="m-0 text-lg">Callbot</h3>
                                <button id="close-popup" className="bg-transparent border-none text-white cursor-pointer" onClick={this.toggleCardVisibility}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </button>
                            </div>
                        )}
                        
                        {!showCallingImage && !isCalling && (
                            <div className="mb-4 space-y-4 mt-2 px-4 form-container">
                                <div className="mb-5 mt-5">
                                    <label htmlFor="initName" id="labelName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nama Lengkap</label>
                                    <input
                                        type="text"
                                        name="name"
                                        id="initName"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Full Name"
                                        required
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="mb-5 mt-5">
                                    <label htmlFor="initEmail" id="labelEmail" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                    <input
                                        type="email"
                                        id="initEmail"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Email"
                                        required
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="mb-5 mt-5">
                                    <label htmlFor="initPhoneNumber" id="labelPhoneNumber" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nomor Telepon</label>
                                    <input
                                        type="text"
                                        id="initPhoneNumber"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Nomor Telepon"
                                        required
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="mb-5 mt-5">
                                    <label htmlFor="initPolicyNumber" id="labelPolicyNumber" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nomor Polis</label>
                                    <input
                                        type="text"
                                        id="initPolicyNumber"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Nomor Polis"
                                        required
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                        )}

                        {!showCallingImage && !isCalling && (
                            <div className="text-center mb-4 mt-2 px-4">
                                <button className="start-call-button bg-gray-800 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded w-full" onClick={this.startCallHandler}>
                                    Start Call
                                </button>
                            </div>
                        )}

                        {showCallingImage && (
                            <div className="flex flex-col items-center justify-center h-full">
                                <img src="/cbot.png" alt="Callbot" className="w-full h-auto pulse-animation" />
                                <p className="mt-4 text-white text-2xl font-bold">Calling...</p>
                            </div>
                        )}

                        {isCalling && (
                            <div className="flex flex-col justify-between h-full px-4">
                                <div className="text-center mb-2">
                                    <h2 className="callbot-title text-xl font-bold mb-1">Callbot</h2>
                                    <span className="duration">{this.formatCallDuration(callDuration)}</span>
                                </div>
                                <div className="flex justify-center mb-4">
                                    <img src="/cbot.png" alt="Callbot" className="yuna-calling" style={gambarStyle} />
                                </div>
                                {showKeypad && (
                    <div className="grid grid-cols-3 gap-1 mt-2 mb-2">
                        {['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'].map((key) => (
                            <button
                                key={key}
                                className={keypadButtonStyle}
                                onClick={() => this.addKeypadInput(key)}
                            >
                                {key}
                            </button>
                        ))}
                    </div>
                )}
                                <div className="flex justify-between items-center mb-4 mt-2">
                                    <button onClick={this.toggleMute} className="bg-gray-200 p-2 rounded-full">
                                        <VolumeMute className={this.state.isMuted ? 'text-red-500' : ''} />
                                    </button>
                                    <button onClick={this.toggleSpeaker} className="bg-gray-200 p-2 rounded-full">
                                        <Speaker className={this.state.isSpeakerOn ? 'text-blue-500' : ''} />
                                    </button>
                                    <button onClick={this.toggleKeypad} className="bg-gray-200 p-2 rounded-full">
                                        <Dialpad />
                                    </button>
                                    <button onClick={this.endCall} className="bg-red-500 p-2 rounded-full text-white">
                                        <CallEnd />
                                    </button>
                                </div>
                            </div>
                        )}

                        {showSatisfactionPopup && (
                            <Dialog open={showSatisfactionPopup} onClose={() => this.setState({ showSatisfactionPopup: false })}>
                                <DialogTitle>Penilaian Kepuasan</DialogTitle>
                                <DialogContent>
                                    <p>Bagaimana pengalaman Anda dengan layanan kami?</p>
                                    <div className="flex space-x-2">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                            <button
                                                key={star}
                                                onClick={() => this.handleRatingClick(star)}
                                                className={`star-icon text-2xl ${rating >= star ? 'text-yellow-400' : 'text-gray-400'}`}
                                            >
                                                &#9733;
                                            </button>
                                        ))}
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <button className="submit-button bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" onClick={this.handleSatisfactionSubmit}>
                                        Submit
                                    </button>
                                </DialogActions>
                            </Dialog>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default Callbot;